<template>
  <v-card class="teros-elevation mt-2" :color="!disabled ? tableColor : ''" :disabled="disabled">
    <!-- <v-card-title class="px-5 pb-0 v-title-cadastro">
        <span class="h5 font-weight-bold">Projeto: <span class="">{{ projeto.titulo }}</span></span>
      </v-card-title> -->
    <MasterDetail ref="masterDetail" :hasExportCSV="false" :hasExportXLSX="false" :customResource="customResource"
      :hasNewButton="false" :actionBarButtons="actionBarButtons" :opts="opts" :cols="cols" :disablePagination="true">

      <!-- <DropdownCaptacao>
        </DropdownCaptacao> -->

      <GModal :opened.sync="formModalOpen" :width="'650px'" title="Criar nova descrição"
        appendTitleIcon="mdi-square-edit-outline">
        <template>
          <v-col>
            <v-row>
              <template v-for="(col, k) in colsModal">
                <input-v class="pl-4" :key="`${k}`" :label="col" :opts="opts" :value="formModalValue[col.key]"
                  :colSize="col.colSize" :editable="col.editable" @input="updateValue(formModalValue, col.key, $event)"
                  @update:valid="updateValidation(col.key, $event)"></input-v>
              </template>
            </v-row>
          </v-col>
          <div class="d-flex justify-end mb-5">
            <v-btn color="primary" @click.stop="criarDescricaoItem()">+ Adicionar nova descrição</v-btn>
          </div>
          <expansion-panel :contentProps="{ eager: true }" :headerProps="{ color: '#81b4ea' }" :items="descricaoList">
            <template v-slot:header="{ item }">
              <span>
                {{ item.descricao }}
              </span>
              <v-btn class="btn-content-duplicate-item" depressed icon title="Duplicar descrição e aquisição"
                @click.stop="duplicarDescricaoItem(item)">
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
              <v-btn class="btn-content-duplicate-item" depressed icon title="Excluir item"
                @click.stop="removerDescricaoItem(item)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <template v-slot:content="{ item, index: itemIndex }">
              <template v-for="(col, k) in itemFields">
                <input-v class="pl-4" :key="`${itemIndex}-${k}`" :value="item[col.key]" :label="col" :opts="opts"
                  :colSize="col.colSize" :editable="col.editable" :valid="validation.get(col.key)"
                  @input="updateValue(item, col.key, $event)" @update:valid="
                    updateValidation(
                      `${col.key}.itens.${itemIndex}`,
                      $event
                    )
                    "></input-v>
              </template>
            </template>
          </expansion-panel>
        </template>
        <template #buttons>
          <v-btn class="px-5 ml-3" color="secondary" dark depressed @click="closeModal()">
            Cancelar
          </v-btn>
          <v-btn depressed color="primary" :disabled="!descricaoList.length" @click="savePareamento()">
            Salvar
          </v-btn>
        </template>
      </GModal>
    </MasterDetail>
  </v-card>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';
import { cloneDeep } from "lodash";

export default {
  props: {
    disabled: Boolean
  },
  components: {
    MasterDetail,
    GModal: () => import('@/components/g-modal.vue'),
    "expansion-panel": () => import("@/components/expansion-panel.vue"),
    "input-v": () => import("@/components/input-v.vue"),
    // DropdownCaptacao: () => import('@/components/dropdown-captacao.vue'),
  },
  // mixins: [],
  // directives: {},

  computed: {
    ...mapGetters(['clientId']),
    actionBarButtons() {
      const actionBarButtons = [];
      const that = this;
      actionBarButtons.push({
        text: "Adicionar",
        icon: "mdi-plus-box-outline",
        show: true,
        action() {
          that.formModalOpen = true;
        }
      });

      return actionBarButtons.filter(({ show }) => show);
    },
    projetoId() {
      return this.$route.params.id;
    },
    customResource() {
      const resource = this.apiResource(`/v1/captacao/${this.clientId}/rubricas/pareamento/${this.projetoId}`);
      const that = this;
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then((result) => {
            that.opts.rubricas = result.rubricas || [];
            that.opts.projetos = result.projetos || [];
            return result.pareamentos;
          })
        }
      }
    },
    cols() {
      return [
        {
          key: 'projeto_id',
          name: 'Projeto',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'projetos', key: 'id', name: 'titulo' },
          rules: [{ rule: 'required' }],
          width: '250px',
          hideInAdd: true,
          tooltip: true,
          truncate: true,
          hideInTable: true,
          hideInform: true,
        },
        {
          key: 'rubrica_id',
          name: 'Rubrica',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'rubricas', key: 'id', name: 'tipo' },
          rules: [{ rule: 'required' }],
          width: '150px',
        },
        {
          key: 'descricao',
          name: 'Descrição',
          type: this.$fieldTypes.TEXTAREA,
          width: '250px',
          rows: 3,
          tooltip: true,
          truncate: true,
          rules: [{ rule: 'required' }, { rule: "max", params: { size: 1000 } }],
          hideInAdd: true
        },
        {
          key: 'finalidade',
          name: 'Finalidade',
          type: this.$fieldTypes.TEXTAREA,
          width: '250px',
          tooltip: true,
          truncate: true,
          hideInAdd: true
        },
        {
          key: 'aquisicao',
          name: 'Aquisição',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'aquisicoes', key: 'aquisicao', name: 'aquisicao' },
          rules: [{ rule: 'required' }],
          width: '100px',
          hideInAdd: true
        },
      ]
    },
    colsModal() {
      return this.cols.filter((show) => { return !show.hideInAdd; });
    },
    itemFields() {
      return [
        {
          key: 'aquisicao',
          name: 'Aquisição',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'aquisicoes', key: 'aquisicao', name: 'aquisicao' },
          rules: [{ rule: 'required' }]
        },
        {
          key: 'descricao',
          name: 'Descrição',
          type: this.$fieldTypes.TEXTAREA,
          width: '250px',
          rows: 1,
          tooltip: true,
          rules: [{ rule: 'required' }, { rule: "max", params: { size: 1000 } }]
        },
        {
          key: 'finalidade',
          name: 'Finalidade',
          type: this.$fieldTypes.TEXTAREA,
          width: '250px',
          rows: 3,
          tooltip: true,
          rules: [{ rule: "max", params: { size: 4000 } }]
        },
      ]
    }
  },
  data() {
    return {
      opts: {
        rubricas: [],
        projetos: [],
        aquisicoes: [
          {
            aquisicao: 'Nacional'
          },
          {
            aquisicao: 'Importada'
          }
        ]
      },
      formModalOpen: false,
      formModalValue: {},
      descricaoList: [],
      validation: new Map(),
      projeto: {
        titulo: '',
      },
    }
  },
  // filters: {},
  created() {
    this.getProjetoSelecionado();
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    savePareamento() {
      const resource = this.apiResource(`/v1/captacao/${this.clientId}/rubricas/pareamento`);
      const descricoes = this.descricaoList.map((item) => { return { aquisicao: item.aquisicao, descricao: item.descricao, finalidade: item.finalidade } });
      const formData = { ...this.formModalValue, ativo: 1, descricoes: descricoes };
      formData['empresa_id'] = this.clientId;
      formData['projeto_id'] = this.projetoId;
      return resource.save(formData, this.projetoId).then((result) => {
        this.resetFormModal();
        return result;
      })
    },
    resetFormModal() {
      this.formModalOpen = false;
      this.formModalValue = {};
      this.descricaoList = [];
      this.$refs.masterDetail.doLoad();
    },
    criarDescricaoItem() {
      const newItem = {
        id: this.descricaoList.length + 1,
        aquisicao: '',
        descricao: '',
        finalidade: ''
      }
      this.descricaoList.push(newItem);
    },
    duplicarDescricaoItem(item) {
      const newItem = cloneDeep(item);
      newItem.id = this.descricaoList.length + 1;
      newItem.descricao = "Duplicado — " + newItem.descricao;
      this.descricaoList.push(newItem);
    },
    removerDescricaoItem(item) {
      const index = this.descricaoList.indexOf(item);
      this.descricaoList.splice(index, 1);
    },
    updateValue: function (target, key, value) {
      target[key] = value;
    },
    updateValidation: function (key, value) {
      this.validation.set(key, value);
    },
    closeModal() {
      this.resetFormModal();
    },
    getProjetoSelecionado() {
      return this.apiResource(`/v1/captacao/projetos/${this.clientId}/${this.projetoId}`).get().then((result) => {
        return this.projeto = result;
      });
    },
  },
  // watch: {},
}
</script>