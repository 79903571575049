var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"teros-elevation mt-2",attrs:{"color":!_vm.disabled ? _vm.tableColor : '',"disabled":_vm.disabled}},[_c('MasterDetail',{ref:"masterDetail",attrs:{"hasExportCSV":false,"hasExportXLSX":false,"customResource":_vm.customResource,"hasNewButton":false,"actionBarButtons":_vm.actionBarButtons,"opts":_vm.opts,"cols":_vm.cols,"disablePagination":true}},[_c('GModal',{attrs:{"opened":_vm.formModalOpen,"width":'650px',"title":"Criar nova descrição","appendTitleIcon":"mdi-square-edit-outline"},on:{"update:opened":function($event){_vm.formModalOpen=$event}},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"px-5 ml-3",attrs:{"color":"secondary","dark":"","depressed":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":!_vm.descricaoList.length},on:{"click":function($event){return _vm.savePareamento()}}},[_vm._v(" Salvar ")])]},proxy:true}])},[[_c('v-col',[_c('v-row',[_vm._l((_vm.colsModal),function(col,k){return [_c('input-v',{key:("" + k),staticClass:"pl-4",attrs:{"label":col,"opts":_vm.opts,"value":_vm.formModalValue[col.key],"colSize":col.colSize,"editable":col.editable},on:{"input":function($event){return _vm.updateValue(_vm.formModalValue, col.key, $event)},"update:valid":function($event){return _vm.updateValidation(col.key, $event)}}})]})],2)],1),_c('div',{staticClass:"d-flex justify-end mb-5"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.criarDescricaoItem()}}},[_vm._v("+ Adicionar nova descrição")])],1),_c('expansion-panel',{attrs:{"contentProps":{ eager: true },"headerProps":{ color: '#81b4ea' },"items":_vm.descricaoList},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.descricao)+" ")]),_c('v-btn',{staticClass:"btn-content-duplicate-item",attrs:{"depressed":"","icon":"","title":"Duplicar descrição e aquisição"},on:{"click":function($event){$event.stopPropagation();return _vm.duplicarDescricaoItem(item)}}},[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),_c('v-btn',{staticClass:"btn-content-duplicate-item",attrs:{"depressed":"","icon":"","title":"Excluir item"},on:{"click":function($event){$event.stopPropagation();return _vm.removerDescricaoItem(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}},{key:"content",fn:function(ref){
var item = ref.item;
var itemIndex = ref.index;
return [_vm._l((_vm.itemFields),function(col,k){return [_c('input-v',{key:(itemIndex + "-" + k),staticClass:"pl-4",attrs:{"value":item[col.key],"label":col,"opts":_vm.opts,"colSize":col.colSize,"editable":col.editable,"valid":_vm.validation.get(col.key)},on:{"input":function($event){return _vm.updateValue(item, col.key, $event)},"update:valid":function($event){return _vm.updateValidation(
                    ((col.key) + ".itens." + itemIndex),
                    $event
                  )}}})]})]}}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }